@import '../colors.less';


.ticket-sidebar {
    background-color: @gray-900;
    min-width: 280px;
    padding: 32px;
    color: @gray-400;
    overflow-y: auto;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 32px;

        .anticon {
            font-size: 14px;
        }

        .anticon-number {
            margin-right: 6px;
        }
    }

    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .ant-btn-text {
            height: 14px;
        }
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px -8px;
        padding: 4px 8px;
        font-size: 16px;
        font-weight: 500;
        color: @gray-300;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
            background-color: @gray-800;
        }

        &:active {
            color: #fff;
            background-color: @gray-700;
        }

        &:focus {
            color: #fff;
            background-color: @gray-700;
        }
    }

    .conversations-list li {
        .ant-avatar {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            line-height: 1.9;
            background-color: #8A48E260;
            font-size: 10px;
            transform: translateY(-1px);
        }

        .ant-badge-dot {
            transform: translate(-7px, -2px);
        }

        button {
            opacity: 0;
            height: 14px;
            z-index: 9;
        }

        .status-indicator {
            position: absolute;
            left: 317px;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            margin: auto 0;
            transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &:hover {
            button {
                opacity: 1;
            }

            .status-indicator {
                opacity: 0;
            }
        }
    }
}



.ticket-list-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    border-left: 1px solid @gray-700;
    background: @gray-800;
}

.ticket-header {
    display: flex;
    justify-content: space-between;
    margin-top: -6px;
    height: 42px;
    white-space: nowrap;

    &-subtitle {
        color: #6D7383;
        display: inline-block;
        margin-left: 8px;
        font-size: 12px;
    }

    &-summary {
        color: #6D7383;
        display: inline-block;
        margin-left: 0px;
        font-size: 12px;
    }

    &-subject {
        color: white;
        font-size: 16px;
        display: inline-flex;
        font-weight: 500;
        max-width: 480px;
        overflow-x: hidden;

        &-addon {
            margin-left: 12px;
            color: #8A90A1;
            font-size: 14px;
        }
    }
}

.ticket-summary-card {
    background: @gray-600 !important;
    padding: 12px 16px !important;
    max-width: 380px;
    max-height: 108px;
    overflow: hidden;
    border-radius: @border-radius;

    p {
        margin-bottom: 0px;

        &:first-child {
            margin-bottom: 4px;
        }
    }

    .anticon-mail {
        margin-right: 6px;
    }

    // Ensures breaks are forced, even if e.g. "nbsp" is used
    word-break: break-all;
    line-break: anywhere;
}

.ticket-message-container {
    margin: 0 -32px -32px -32px;
    border-top: 1px solid #282E3F;
    overflow: hidden;
    min-width: 600px;

    .message-box {
        overflow: auto;
        height: calc(100vh - 416px);
        padding-bottom: 64px;
        padding-right: 32px;
        padding-left: 32px;
    }

    .input-box {
        padding: 0 32px 32px 32px;

        .scroll-gradient {
            height: 64px;
            transform: translateY(-64px);
            background-image: linear-gradient(transparent, #1f2536);
            margin: 0 -32px;
            margin-right: -26px;
        }

        textarea {
            padding: 24px;
            margin-top: -64px;
        }
    }
}

.user-message {
    .triangle-left {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid @gray-500;
        position: absolute;
        left: -8px;
        top: 8px;
    }

    .triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid @gray-800;
        position: absolute;
        top: -12px;
        left: 1px;
    }
}

.agent-message {
    .triangle-right {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid @gray-700;
        position: absolute;
        right: -8px;
        top: 8px;
    }
}

.agent-message {
    .anticon-check:nth-child(2) {
        margin-left: 3px;
    }

    .anticon-check:nth-child(3) {
        margin-left: -5px;
    }

    .anticon-check svg {
        padding-top: 1px;
    }

    .check-read {
        color: @blue;
    }
}

.message-timestamp {
    position: absolute;
    right: 16px;
    bottom: 12px;
    color: #6D7383;
    font-size: 12px;
}

/* Attachments in inline message + user detail panel */
.user-message,
.agent-message {
    .message-body {
        p {
            margin-bottom: 0;
        }
    }

    .ant-image {
        width: 22%;
        min-width: 120px;
        height: 120px;
        margin-right: 3%;
        margin-bottom: 16px;

        &-img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        .ant-image-mask {
            background-color: rgba(31, 37, 54, 0.6);
        }
    }

    .attachment-file {
        display: flex;
        align-items: center;

        &-badge {
            color: white;
            font-weight: 500;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 40px;
            height: 40px;
            background: #5D2E2C;
            border: 1px solid @red;
            border-radius: 8px;
            margin-right: 12px;
        }

        &-text {
            transform: translateY(2px);
        }

        &-text span {
            color: @gray-400;
        }
    }
}

.ant-layout-extra {
    .ant-image {
        width: 79px;
        height: 79px;
        margin-right: 12px;
        margin-bottom: 5px;

        &-img {
            object-fit: cover;
            width: 79px;
            height: 79px;
            border-radius: 6px;
        }

        .ant-image-mask {
            background-color: rgba(31, 37, 54, 0.6);
        }
    }

    .ant-collapse-content-box div:nth-child(3n+3) {
        margin-right: 0px;
    }

    .attachment-file {
        transform: translateY(-6px);
        display: inline-block;
        background: #5D2E2C;
        border: 1px solid @red;
        border-radius: 6px;
        width: 79px;
        height: 79px;
        margin-right: 12px;
        margin-bottom: 5px;
        margin-top: 6px;

        &-badge {
            color: white;
            font-weight: 500;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 8px 0 5px 0;
        }

        &-text {
            background: @gray-800;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            padding: 3px 6px;
            border-radius: 0 0 7px 7px;
        }

        &-text span {
            color: @gray-400;
        }
    }
}