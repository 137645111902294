/* Settings general styles ================================================================================================== */
.settings-sidebar {
    width: 281px;
    border-right: 1px solid @gray-700;
    height: 100vh;
    padding-top: 32px;
    position: fixed;
    overflow-y: auto;
    background-color: #181d2c;

    ul {
        padding-left: 32px;
        list-style: none;
    }

    &-title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &-title h6 {
        color: @gray-400;
    }

    &-title>.anticon {
        font-size: 16px;
        margin-right: 6px;
        color: @gray-400;
        border-radius: 6px;
        float: left;
    }

    .ant-btn-text {
        font-size: 16px;
        font-weight: 500;
        height: 32px;
    }
}



/* User management page ================================================================================================== */
.user-management-table {
    .ant-table {
        background: transparent;

        .ant-table-thead .ant-table-cell {
            padding: 0px 16px;
        }

        .ant-table-tbody .ant-table-cell {
            padding: 16px;
        }

        .ant-table-tbody>tr.ant-table-row:hover>td {
            background: transparent !important;
        }
    }

    .project-img {
        margin-right: 12px;
        margin-bottom: 2px;
    }

    .add-project-btn {
        color: @gray-300;
        background-color: @gray-800;

        &:hover {
            color: white;
            background-color: @gray-700;
        }
    }
}

.project-img {
    width: 40px;
    height: 40px;
    border-radius: 6px;
}

.invite-user-wrap {
    display: flex;
    margin-top: 24px;

    .invite-user-input {
        width: 100%;
        margin-right: 16px;
    }

    p {
        margin-bottom: 6px;
        color: white !important;
    }

    div {
        text-align: left;
    }
}

.add-more-btn {
    margin-top: 24px;
    color: @gray-400;
}