@import 'colors.less';
@import 'misc.less';

// I cannot express how much I hate these Chinese fucks
* {
  animation-duration: 0s !important;
  transition: none !important;
}

body {
  margin: 0;
  background-color: var(--main-background);
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-color);
}

p {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
}

h1 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0px;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

h6 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: @gray-400;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  padding: 8px;
}

::-webkit-scrollbar-track {
  width: 6px;
  max-width: 6px;
  background: var(--scrollbar-track);
  border-radius: 8px;
  padding: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 8px;
}

::-webkit-scrollbar-corner {
  background: var(--scrollbar-thumb);
}



/* Nav & General layout ================================================================================================== */
.ant-layout-sider {
  width: 80px;
  position: fixed;
  left: 0;
  height: 100vh;
  border-right: var(--nav-border);
  background: var(--sidebar-background);
}

.ant-layout-content {
  margin-left: 80px !important;
  margin-right: 360px !important;
  height: 100vh;
  overflow-y: auto;

  overflow-x: hidden;
}

.ant-layout-extra {
  border-left: var(--layout-extra-border);
  width: 360px;
  position: fixed;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--layout-extra-background);
}

@media (max-width: 1200px) {

  /* Hide right sidebar on smaller screens */
  .ant-layout-content {
    margin-right: 0px !important;
  }

  .ant-layout-extra {
    transform: translateX(360px);
  }

  .anticon-menu-unfold {
    transform: rotate(180deg);
  }
}

.ant-layout-extra.closed {
  transform: translateX(360px);
}

.ant-layout-content.extra-closed {
  margin-right: 0px !important;
}

.logo {
  height: 50px;
  width: 50px;
  margin-top: 24px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  display: block;
}

.menu-divider {
  border-top: 1px solid @gray-700;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ant-menu-item-selected {
  background-color: #1F86EE !important;
  border-radius: 8px;
}

.ant-menu-item {
  border-radius: 8px;
}

.ant-menu {
  padding: 0 15px;
  border-right: var(--nav-border);
}

.ant-tooltip-placement-rightBottom .ant-tooltip-inner {
  background-color: @gray-700;
  border: 1px solid @gray-600;
  padding: 10px 16px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .ant-btn-text {
    height: 32px;
  }

  hr {
    border-color: #33394a;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .anticon {
    font-size: 12px;
  }
}

hr {
  background-color: @gray-700;
  border: none;
  border-top: 1px solid @gray-700;
}

@media only screen and (max-width: 991px) {
  .site-layout-background {
    min-width: 1100px;
  }
}

.ant-comment-content {
  padding: 12px;
  background-color: #1b2232;
  border-radius: 18px;
  border-top-left-radius: 0;
}

/* Help widget */
.help-widget-btn {
  position: absolute;
  right: 24px;
  bottom: 24px;

  .ant-avatar-icon {
    background: @blue;
    width: 50px;
    height: 50px;
    line-height: 2;
    font-size: 26px;
  }
}

.ant-dropdown-placement-topRight {
  /*force dropdown over tooltip*/
  z-index: 1080;
}

.help-widget-frame {
  padding: 16px 24px !important;
  width: 350px;
  bottom: 16px;

  p {
    margin-bottom: 24px;
  }

  .anticon-close {
    font-size: 18px !important;
  }

  .help-widget-item {
    background: @gray-600;
    padding: 16px;
    margin-bottom: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 8px;
    }

    .ant-image-img {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background: @gray-700;
      margin-right: 16px;
    }

    .text-container span {
      color: @gray-300;
    }

    &:hover {
      background: @gray-500;

      .anticon-arrow-right {
        color: white;
      }
    }

    .anticon-arrow-right {
      font-size: 22px;
    }

    .anticon-camera {
      margin-right: 6px;
    }
  }
}



/* Progress bar ================================================================================================== */
.top-progress-bar {
  height: 4px;
  left: 0;
  right: 0;
  z-index: 1;
  position: fixed;
  background: #20395b;
  width: 100%;
  overflow: hidden;
}

.top-progress-bar-value {
  width: 100%;
  height: 100%;
  animation: indeterminateAnimation 1s infinite linear;
  background: linear-gradient(90deg, transparent 0%, #2086ee 50%, transparent 100%);
  transform-origin: 0% 0%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(-100%) scaleX(1);
  }

  100% {
    transform: translateX(100%) scaleX(1);
  }
}



/* Assign module ================================================================================================== */
.assign-module {
  cursor: pointer;

  .ant-avatar-circle {
    border-color: @gray-600;
    color: @gray-300;
    font-size: 15px;
  }

  .ant-dropdown-trigger {
    min-width: 0px;
    padding: 0;
    display: block;
  }

  .ant-dropdown-trigger:hover {
    .ant-avatar {
      color: white;
      background: @gray-500 !important;
    }
  }

  &-menu {
    padding: 12px 14px !important;
    max-height: 360px;
    overflow: auto;

    .ant-dropdown-menu-item {
      padding: 6px 8px;
      margin: 0 -8px;

      span:not(.ant-avatar):not(.anticon-close) {
        white-space: nowrap;
        overflow: hidden;

      }

      .ant-btn {
        height: 10px;
        float: right;
        margin-top: 6px;
        opacity: 0;
        margin-left: 6px;

        .anticon-close {
          font-size: 14px !important;
        }
      }

      &-active {
        .ant-btn {
          opacity: 1;
        }
      }
    }

    .assign-myself .assign-module-name {
      color: @blue !important;
    }
  }

  &-name {
    color: @gray-300;
    display: inline-block;
    max-width: 140px;
    margin-bottom: -9px;
    margin-left: 3px;
  }
}



/* User details sidebar ================================================================================================== */
.ant-collapse {
  border: none;

  &-item {
    margin-bottom: 24px;
    border-radius: 8px !important;
    border: none !important;
    background: @gray-800;
  }

  &-header {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 10px !important;
  }

  &-content {
    border-radius: 8px !important;
    border: none;

    &-box {
      padding: 1px 16px !important;
    }
  }
}

.ant-collapse {
  .ant-collapse-item {
    &:not(.ant-collapse-item-active) {
      .ant-collapse-header {
        color: @gray-400;
      }

      .ant-collapse-arrow {
        color: @gray-400;
      }
    }

    .ant-collapse-header {
      display: block;

      .ant-collapse-arrow {
        float: right;
        margin-top: 5px;
        margin-right: 0px;
        font-size: 15px;
      }
    }
  }
}

.previous-tickets {
  .ant-collapse-content {
    max-width: 280px;
    overflow: hidden;

    .ant-btn {
      height: 32px;

      &:first-child {
        margin-top: -4px;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }

    .anticon {
      margin-right: 6px;
      font-size: 15px;
    }
  }

  .ant-btn-text:not(:hover, :active, :focus) {
    color: @gray-200;
  }
}



/* Login & Register ================================================================================================== */
.login-module,
.register-module {
  h1 {
    text-align: center;
    line-height: 1;
    margin-bottom: 10px;
  }

  p {
    text-align: center;
  }

  .ant-input {
    height: 49px;
  }

  .ant-btn {
    height: 49px;
    border-width: 2px;
    border-radius: 6px;
  }

  .input-extra-options.ant-form-item {
    margin-bottom: 8px;

    &-with-help {
      margin-bottom: 0px;
    }
  }

  .extra-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }

  .ant-btn-primary {
    margin-top: 14px;
  }

}

.hr-text {
  margin: 16px auto 24px auto;
  max-width: 280px;
  height: 12px;
  border-bottom: 1px solid #282E3F;
  text-align: center;

  span {
    background-color: #161C2D;
    color: #33394A;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 0 10px;
  }
}

.company-logo img {
  width: auto;
  max-height: 70px;
  max-width: 100px;
  padding: 12px;
  filter: grayscale(1) brightness(5);
  margin: auto 0;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}



/* Login & Register ================================================================================================== */
.start-wizard {
  max-width: 420px;
  width: calc(100% - 16px) !important;

  .ant-modal-content .ant-modal-body {
    padding: 48px;
  }

  @media (max-width: 420px) {
    .ant-modal-content .ant-modal-body {
      padding: 32px;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 72px;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 24px;
    background-color: @gray-700;
    border: 1px dashed @gray-500;

    &:hover {
      border: 1px dashed @gray-300;
    }

    .ant-upload {
      text-align: left;
      justify-content: flex-start;

      .upload-block {
        display: flex;
        align-items: center;

        &-img {
          display: block;
          width: 70px;
          height: 70px;
          background: @gray-600;
          border-radius: 8px;
          margin-right: 16px;
        }
      }
    }
  }

  .ant-form-item-label {
    margin-bottom: -4px;
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-input-affix-wrapper {
    margin-bottom: 2px;
  }

  .ant-form-item:last-child {
    margin-bottom: 0px;
  }

  p {
    font-size: 16px;
  }

  small {
    color: @gray-400;
    line-height: 1.4;
  }
}