/* Buttons ================================================================================================== */
.ant-btn {
  font-size: 14px;
  line-height: 0;
  padding: 0 24px;
  height: 40px;
  color: #fff;
  background-color: transparent;
  border-color: @gray-500;

  &:hover {
    color: #fff;
    background-color: @gray-500;
    border-color: @gray-500;
  }

  &:active {
    color: #fff;
    background-color: @gray-500;
    border-color: @gray-500;
  }

  &:focus {
    color: #fff;
    background-color: @gray-500;
    border-color: @gray-500;
    box-shadow: 0 0 0 0.2rem #33394a80;
  }

  &-sm {
    height: 28px;
    padding: 7px 12px;
    font-size: 14px;
  }
}

.ant-btn-primary {
  background-color: @blue;
  border-color: @blue;

  &:hover {
    background-color: #1073d6;
    border-color: #1073d6;
  }

  &:active {
    background-color: #1073d6;
    border-color: #1073d6;
  }

  &:focus {
    background-color: #1073d6;
    border-color: #1073d6;
    box-shadow: 0 0 0 0.2rem #1f86ee40;
  }
}

.ant-btn-text {
  color: @gray-300;
  border: none;
  padding: 0px;

  &:hover {
    color: white;
    background: none;
    border: none;
  }

  &:active {
    color: white;
    background: none;
    border: none;
  }

  &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.ant-btn-link {
  color: @blue;
  border: none;
  padding: 0px;
  height: inherit;

  &:hover {
    color: #0e60b3;
    background: none;
    border: none;
  }

  &:active {
    color: #0e60b3;
    background: none;
    border: none;
  }

  &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.ant-btn>.anticon {
  line-height: 0;
  font-size: 16px;
}

.ant-btn:not(.ant-dropdown-trigger) span:nth-child(2) {
  margin-left: 5px !important;
}

.ant-btn-text>.anticon {
  font-size: 21px;
}

.ant-dropdown-trigger>.anticon,
.ant-dropdown-trigger:last-child {
  margin-right: 0px !important;
}

.ant-layout {
  background: var(--main-background);
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: var(--sidebar-background);
}


/* Dropdown buttons ================================================================================================== */
.ant-btn.ant-dropdown-trigger.dropdown-list {
  min-width: 120px;
  font-size: 14px;
  padding: 0 14px;
  color: @gray-300;
  background-color: #1F2536;
  border-color: var(--gray-500);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    color: @gray-300;
    background-color: @gray-700;
    border-color: @gray-500;
  }

  &:active {
    color: @gray-300;
    background-color: @gray-700;
    border-color: @blue;
  }

  &:focus {
    color: @gray-300;
    background-color: @gray-700;
    border-color: @blue;
    box-shadow: none;
  }
}

.ant-dropdown-menu {
  background: @gray-700;
  border: 1px solid @gray-600;
  padding: 4px 6px;

  &-item {
    margin: 2px 0px 2px 0px;
    padding: 4px 8px;
    border-radius: 6px;

    &:hover {
      background: @gray-600;
    }

    &:active {
      background: @gray-600;
    }

    &:focus {
      background: @gray-600;
    }
  }
}

.ant-dropdown-trigger.dropdown-list>.anticon,
.ant-dropdown-menu-item>.anticon,
.ant-dropdown-menu-title-content .anticon {
  font-size: 15px !important;

  &:first-child {
    margin-right: 6px;
  }
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 0px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: @gray-300 !important;
}

.ant-dropdown-menu-body .ant-btn-text {
  height: 32px;
}



/* Inputs ================================================================================================== */
.ant-input,
.ant-input-affix-wrapper {
  background-color: @gray-700;
  border: 1px solid @gray-600;
  border-radius: 6px;
  padding: 8px 12px;
  color: @gray-200;

  &:hover {
    border-color: @gray-400 !important;
  }

  &:focus {
    border-color: @blue !important;
  }

  &:active {
    border-color: @blue !important;
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: @blue !important;
}

.ant-input-prefix {
  color: @gray-400;
  margin-right: 6px;
}

.ant-input-affix-wrapper>input.ant-input {
  border-radius: 0;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: @gray-400 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: @gray-400 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray-400 !important;
}

.ant-checkbox-inner {
  border-radius: 3px;
  background-color: transparent;
  border-color: #3F4556;
}

.ant-input-disabled {
  background-color: @gray-800 !important;
  color: #4a5061 !important;

  &:hover {
    border-color: @gray-600 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}



/* Tooltips ================================================================================================== */
.ant-tooltip-inner {
  color: @gray-200;
  background-color: @gray-600;
  border-radius: 6px;
}

.ant-menu-inline-collapsed-tooltip a {
  color: @gray-200;
}

.ant-tooltip-arrow-content {
  background-color: @gray-600;
}



/* Alerts ================================================================================================== */
.ant-alert {
  padding: 8px 16px;

  &-message {
    color: @gray-200;
    margin-top: 2px;
  }

  &-with-description {
    padding: 16px;

    .ant-alert-message {
      color: white;
    }
  }

  &-close-icon .anticon-close {
    color: @gray-200;

    &:hover {
      color: white
    }
  }

  &-success {
    background-color: @green-tp;
    border: 1px solid @green;

    .ant-alert-icon {
      color: @green;
    }
  }

  &-info {
    background-color: @blue-tp;
    border: 1px solid @blue;

    .ant-alert-icon {
      color: @blue;
    }
  }

  &-warning {
    background-color: @yellow-tp;
    border: 1px solid @yellow;

    .ant-alert-icon {
      color: @yellow;
    }
  }

  &-error {
    background-color: @red-tp;
    border: 1px solid @red;

    .ant-alert-icon {
      color: @red;
    }
  }
}





/* Tags ================================================================================================== */
.ant-tag {
  border-radius: 50px;
  border: none;
  padding: 1px 13px;
  font-size: 12px;

  &-success {
    background-color: #38cb8921;
    color: #38CB89;
  }

  &-processing {
    background-color: #1F86EE21;
    color: #1F86EE;
  }

  &-closed {
    background-color: #B5BBCC21;
    color: #B5BBCC;
  }

  &-error {
    background-color: #CB383821;
    color: #CB3838;
  }

  &-warning {
    background-color: #FFAB0021;
    color: #FFAB00;
  }
}


.ant-table {
  background: var(--table-background);
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: var(--table-background-hover);
}


/* Cards ================================================================================================== */
.ant-card {
  margin-bottom: 32px;
  background: var(--card-background);
}

.ant-card-head {
  padding: 28px 32px;
  border-bottom: var(--card-border);
}

.ant-card-head-title {
  font-size: 24px;
  font-weight: 600;
  padding: 0px;
  color: var(--card-title-color);
}

.ant-card-bordered {
  border: var(--card-border);
}

.ant-card-body {
  padding: 32px;
}

.ant-card-actions {
  padding: 32px 32px;
}

.ant-card-actions>li {
  margin: 0px;
}

.ant-card-actions>li>span:hover {
  color: inherit;
}



/* Modals ================================================================================================== */
.ant-modal-content {
  .ant-modal-body {
    padding: 32px;

    p {
      color: @gray-300;
    }
  }

  .ant-modal-close:focus,
  .ant-modal-close:hover {
    color: white;
  }
}