@font-family: proxima-nova, sans-serif;

// Colors
@blue: #1f86ee;
@purple: #8a48e2;
@green: #38cb89;
@yellow: #ffab00;
@red: #ff5630;
@blue-tp: rgba(31, 134, 238, 0.12);
@purple-tp: rgba(138, 72, 226, 0.12);
@green-tp: rgba(56, 203, 137, 0.12);
@yellow-tp: rgba(255, 171, 0, 0.12);
@red-tp: rgba(255, 86, 48, 0.12);

// Grays
@white: #fff;
@gray-100: #f6f6f9;
@gray-200: #b5bbcc;
@gray-300: #8a90a1;
@gray-400: #6d7383;
@gray-500: #3f4556;
@gray-600: #33394a;
@gray-700: #282e3f;
@gray-800: #1f2536;
@gray-900: #181d2c;
@gray-dark: #151a27;
@black: #000;

@border-radius: 8px;

[data-theme="dark"] {

    // Colors
    --blue: @blue;
    --purple: @purple;
    --green: @green;
    --yellow: @yellow;
    --red: @red;
    --blue-tp: @blue-tp;
    --purple-tp: @purple-tp;
    --green-tp: @green-tp;
    --yellow-tp: @yellow-tp;
    --red-tp: @red-tp;

    // Grays
    --white: #fff;
    --gray-100: #f6f6f9;
    --gray-200: #b5bbcc;
    --gray-300: #8a90a1;
    --gray-400: #6d7383;
    --gray-500: #3f4556;
    --gray-600: #33394a;
    --gray-700: #282e3f;
    --gray-800: #1f2536;
    --gray-900: #181d2c;
    --gray-dark: #151a27;
    --black: #000;

    --font-family: @font-family;

    // Elements
    --primary-color: var(--blue);
    --layout-sider-background: var(--gray-dark);
    --menu-dark-bg: var(--gray-dark);
    --layout-body-background: var(--gray-900);
    --menu-dark-color: var(--gray-400);
    --heading-color: var(--white);
    --alert-message-color: var(--gray-500);
    @component-background: @gray-800;
    --component-background: var(--gray-800);
    --text-color: @gray-200;
    --header-color: white;
    --text-color-secondary: var(--gray-400);
    --border-color-base: var(--gray-700);
    --border-color-split: var(--gray-700);
    --border-radius-base: 8px;
    --border-radius: @border-radius;
    --tag-default-color: var(--blue);
    --tag-default-bg: var(--blue-tp);
    --descriptions-bg: darken(@component-background, 2%);
    --tabs-card-head-background: darken(@component-background, 2%);
    --select-item-selected-bg: darken(@component-background, 2%);
    --select-item-active-bg: darken(@component-background, 2%);
    --menu-icon-size-lg: 25px;
    --menu-item-height: 56px;
    --menu-inline-toplevel-item-height: 50px;
    --menu-item-vertical-margin: 10px;
    --layout-header-background: none;
    --layout-header-padding: 0px 32px;
    --layout-header-color: var(--gray-400);
    --layout-header-height: 32px;
    --table-header-bg: transparent;
    --checkbox-color: var(--gray-400);
    --checkbox-size: 16px;
    --checkbox-border-width: 2px;
    --table-header-color: var(--gray-400);
    --table-row-hover-bg: fade(@gray-700, 50%);
    --table-padding-vertical: 10px;
    --badge-text-color: var(--white);
    --divider-color: var(--gray-700);
    --table-background: @gray-800;
    --table-background-hover: #282e3f80;

    // Root styles
    --main-background: @gray-900;
    --card-background: @gray-800;
    --sidebar-background: @gray-dark;
    --card-title-color: white;
    --nav-border: 1px solid @gray-700;
    --layout-extra-background: @gray-900;
    --layout-extra-border: 1px solid #282e3f;

    // Scroll bar
    --scrollbar-track: #181D2C;
    --scrollbar-thumb: #33394A;

    // Cards
    --card-border: 1px solid #282e3f;
}

[data-theme="light"] {
    --font-family: @font-family;

    // Colors
    --blue: @blue;
    --purple: @purple;
    --green: @green;
    --yellow: @yellow;
    --red: @red;
    --blue-tp: @blue-tp;
    --purple-tp: @purple-tp;
    --green-tp: @green-tp;
    --yellow-tp: @yellow-tp;
    --red-tp: @red-tp;

    // Grays
    --white: #fff;
    --gray-100: #f6f6f9;
    --gray-200: #b5bbcc;
    --gray-300: #8a90a1;
    --gray-400: #6d7383;
    --gray-500: #3f4556;
    --gray-600: #33394a;
    --gray-700: #282e3f;
    --gray-800: #1f2536;
    --gray-900: #181d2c;
    --gray-dark: #151a27;
    --black: #000;

    // Elements
    --primary-color: var(--blue);
    --layout-sider-background: var(--gray-dark);
    --menu-dark-bg: var(--gray-dark);
    --layout-body-background: var(--gray-900);
    --menu-dark-color: var(--gray-400);
    --heading-color: var(--white);
    --alert-message-color: var(--gray-500);
    @component-background: @gray-800;
    --component-background: @component-background;
    --text-color: @gray-900;
    --header-color: black;
    --text-color-secondary: var(--gray-400);
    --border-color-base: var(--gray-700);
    --border-color-split: var(--gray-700);
    --border-radius-base: 8px;
    --border-radius: 8px;
    --tag-default-color: var(--blue);
    --tag-default-bg: var(--blue-tp);
    --descriptions-bg: darken(@component-background, 2%);
    --tabs-card-head-background: darken(@component-background, 2%);
    --select-item-selected-bg: darken(@component-background, 2%);
    --select-item-active-bg: darken(@component-background, 2%);
    --menu-icon-size-lg: 25px;
    --menu-item-height: 56px;
    --menu-inline-toplevel-item-height: 50px;
    --menu-item-vertical-margin: 10px;
    --layout-header-background: none;
    --layout-header-padding: 0px 32px;
    --layout-header-color: var(--gray-400);
    --layout-header-height: 32px;
    --table-header-bg: transparent;
    --checkbox-color: var(--gray-400);
    --checkbox-size: 16px;
    --checkbox-border-width: 2px;
    --table-header-color: var(--gray-400);
    --table-row-hover-bg: fade(@gray-700, 50%);
    --table-padding-vertical: 10px;
    --badge-text-color: var(--white);
    --divider-color: var(--gray-700);
    --table-background: white;
    --table-background-hover: @gray-100;

    // Root styles
    --main-background: #f3f4f6;
    --card-background: white;
    --sidebar-background: white;
    --card-title-color: @gray-dark;
    --nav-border: none;
    --layout-extra-background: white;
    --layout-extra-border: none;

    // Scroll bar
    --scrollbar-track: @gray-200;
    --scrollbar-thumb: @gray-400;

    // Cards
    --card-border: none;
}