// Various utility classes that facilitate modeling common attributes.

.flex {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

.overflow-hidden {
    overflow: hidden;
}